import React, {
  Fragment,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import styled from "styled-components";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import Masonry from "react-masonry-component";
import EventCard from "../components/EventCard";
import PageLoader from "../components/PageLoader";
import useNavigateToEventDetails from "../hooks/useNavigateToEventDetails.hook";
import useListEventsHook from "../hooks/useListEvents.hook";
import NoPageContent, { NoPageContentTypes } from "../components/NoPageContent";
import { RolesEnum } from "@sellout/models/.dist/interfaces/IRole";
import usePermission from "../hooks/usePermission.hook";
import { AppNotificationTypeEnum } from "../models/interfaces/IAppNotification";
import * as AppActions from "../redux/actions/app.actions";
import { PaddedPage } from "../components/PageLayout";
import { Colors, Loader, LoaderSizes } from "@sellout/ui";
import useNavigateToCreateEvent from "../hooks/useNavigateToCreateEvent.hook";
import { EventQueryEnum } from "../models/enums/EventQueryEnum";
import { useSelector, useDispatch } from "react-redux";
import { BackstageState } from "../redux/store";
import { useLocation } from "react-router";
import { LoaderContainer } from "../components/account/AccountStyle";

const SubNavContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 90vh;
`;
type SubNavItemProps = {
  active: boolean;
};
const SubNavItem = styled.div<SubNavItemProps>`
  transition: all 0.2s;
  color: ${(props) => (props.active ? `${Colors.Grey1}` : `${Colors.Grey4}`)};
  font-weight: 600;
  font-size: 1.8rem;
  cursor: pointer;
  margin-right: 24px;
`;

type EventListProps = {
  match: any;
};

const EventList: React.FC<EventListProps> = () => {
  /* Hooks */
  const location = useLocation();
  const dispatch = useDispatch();

  const hasPermission = usePermission();
  const navigateToEventDetails = useNavigateToEventDetails();
  const navigateToCreateEvent = useNavigateToCreateEvent();
  const params: any = new URLSearchParams(location.search);
  const [queryKey, setQueryKey] = React.useState(
    EventQueryEnum.MainEventListUpComing
  );

  const scrollContainer = useRef<HTMLDivElement>(null);
  const [hasMoreEvents, setHasMoreEvents] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { eventQueryHash } = useSelector((state: BackstageState) => state.app);
  const query = eventQueryHash[queryKey];
  const isPastEvents = queryKey === EventQueryEnum.MainEventListPast;
  const { events, loading, fetchMore } = useListEventsHook({
    variables: {
      query,
      pagination: {
        pageSize: 25,
        pageNumber: 1,
      },
      context: {
        debounceKey: "QUERY_EVENTS",
        debounceTimeout: 250,
      },
    },
  });
console.log(events);

  /** State */
  const showScannerNotification = () =>
    dispatch(
      AppActions.showNotification(
        "Web scanning coming soon. Please download the 'Sellout Access Control' app in the ios store or google play store from your phone in order to scan tickets.",
        AppNotificationTypeEnum.Warning
      )
    );
  const isScanner = hasPermission && !hasPermission(RolesEnum.BOX_OFFICE);

  const sortEvents = (events: IEventGraphQL[]) => {
    return isPastEvents
      ? [...events].sort((a, b) => {
          if (
            a.schedule &&
            b.schedule &&
            a.schedule.startsAt &&
            b.schedule.startsAt
          ) {
            return b.schedule.startsAt - a.schedule.startsAt;
          }
          return 0;
        })
      : [...events].sort((a, b) => {
          if (
            a.schedule &&
            b.schedule &&
            a.schedule.startsAt &&
            b.schedule.startsAt
          ) {
            return b.schedule.startsAt - a.schedule.startsAt;
          }
          return 0;
        });
  };

  useEffect(() => {
    if (EventQueryEnum[params.get("type")]) {
      setQueryKey(params.get("type"));
    }
  }, [params.get("type")]);

  const handleScroll = useCallback(() => {
    if (
      !scrollContainer.current ||
      !fetchMore ||
      !hasMoreEvents ||
      isFetchingMore
    )
      return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current;
    if (scrollTop + clientHeight >= scrollHeight - 500) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          pagination: {
            pageSize: 10,
            pageNumber: Math.ceil((events?.length || 0) / 10) + 1,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setIsFetchingMore(false);

          if (
            !fetchMoreResult ||
            !fetchMoreResult?.events ||
            fetchMoreResult?.events?.length === 0
          ) {
            setHasMoreEvents(false);
            return prev || { events: [] };
          }

          const existingEventIds = new Set(
            (prev?.events ?? []).map((event: IEventGraphQL) => event._id)
          );
          const newEvents = fetchMoreResult.events.filter(
            (event) => !existingEventIds.has(event._id)
          );

          return {
            ...prev,
            events: [...(prev?.events ?? []), ...newEvents],
          };
        },
      });
    }
  }, [events?.length, fetchMore, hasMoreEvents, isFetchingMore]);

  useEffect(() => {
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, queryKey]);

  useEffect(() => {
    setHasMoreEvents(true);
    setIsFetchingMore(false);
  }, [queryKey]);

  /* Render */
  return (
    <ScrollContainer ref={scrollContainer}>
      <Fragment>
        <PageLoader nav={true} fade={Boolean(!loading)} />
        <PaddedPage>
          <SubNavContainer>
            <SubNavItem
              onClick={() => setQueryKey(EventQueryEnum.MainEventListUpComing)}
              active={EventQueryEnum.MainEventListUpComing === queryKey}
            >
              Upcoming
            </SubNavItem>
            <SubNavItem
              onClick={() => setQueryKey(EventQueryEnum.MainEventListPast)}
              active={EventQueryEnum.MainEventListPast === queryKey}
            >
              Past
            </SubNavItem>
            <SubNavItem
              onClick={() => setQueryKey(EventQueryEnum.MainEventListCancelled)}
              active={EventQueryEnum.MainEventListCancelled === queryKey}
            >
              Cancelled
            </SubNavItem>
          </SubNavContainer>

          {events && events?.length > 0 && !loading ? (
            <Masonry
              options={{ horizontalOrder: true }}
              enableResizableChildren
            >
              {sortEvents(events).map((event: IEventGraphQL) => (
                <Fragment key={event._id}>
                  {!event.cancel &&
                    EventQueryEnum.MainEventListUpComing === queryKey && (
                      <EventCard
                        key={event._id}
                        event={event}
                        margin="0 24px 24px 0"
                        onClick={() => {
                          if (isScanner) {
                            showScannerNotification();
                          } else if (event?.published) {
                            navigateToEventDetails(event._id);
                          } else {
                            navigateToCreateEvent(event._id);
                          }
                        }}
                      />
                    )}
                  {!event.cancel &&
                    EventQueryEnum.MainEventListPast === queryKey && (
                      <EventCard
                        key={event._id}
                        event={event}
                        margin="0 24px 24px 0"
                        onClick={() => {
                          if (isScanner) {
                            showScannerNotification();
                          } else if (event?.published) {
                            navigateToEventDetails(event._id);
                          } else {
                            navigateToCreateEvent(event._id);
                          }
                        }}
                      />
                    )}
                  {event.cancel &&
                    EventQueryEnum.MainEventListCancelled === queryKey && (
                      <EventCard
                        key={event._id}
                        event={event}
                        margin="0 24px 24px 0"
                        onClick={() => {
                          if (isScanner) {
                            showScannerNotification();
                          } else if (event?.published) {
                            navigateToEventDetails(event._id);
                          } else {
                            navigateToCreateEvent(event._id);
                          }
                        }}
                      />
                    )}
                </Fragment>
              ))}
            </Masonry>
          ) : (
            !loading && <NoPageContent type={NoPageContentTypes.Event} />
          )}
          {isFetchingMore && (
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} color={Colors.Orange} />
            </LoaderContainer>
          )}
        </PaddedPage>
      </Fragment>
    </ScrollContainer>
  );
};

export default EventList;
