import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Button, {
  ButtonTypes,
  ButtonStates,
} from "@sellout/ui/build/components/Button";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalTypes,
} from "./Modal";
import * as Time from "@sellout/utils/.dist/time";
import { Colors} from "@sellout/ui";
import { BackstageState } from "../../redux/store";
import SelloutDarkLogo from "../../assets/images/Sellou-dark.png";
import * as EventActions from "../../redux/actions/event.actions";
import { useMutation } from "@apollo/client";
import EVENT_EMAIL_TEMPLATE from '@sellout/models/.dist/graphql/mutations/eventEmailTemplate.mutation';
import { AppNotificationTypeEnum } from "../../models/interfaces/IAppNotification";
import { getErrorMessage } from "@sellout/ui/build/utils/ErrorUtil";


const ButtonContainer = styled.div`
  display: flex;
`;
const Text = styled.div`
  font-family: "neue-haas-grotesk-display", sans-serif;
  padding: 0px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${Colors.Grey1};
`;
const EmailHoldersPreviewModal = () => {
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  let title = "Preview Message";
  let confirmText = "SUBMIT";
  let editText = "EDIT";

  const eventState = useSelector((state: BackstageState) => state.event);
  const { eventId, eventsCache } = eventState;

  const event = eventsCache[eventId];
  const previewEmailSubject = event?.eventEmailSubject
  const previewEmailBody = event?.eventEmailBody


  const eventEmailBody = useSelector(
    (state: BackstageState) => event?.eventEmailBody
  );
    const setEventEmailSubject = (eventEmailSubject: string) => {
      dispatch(EventActions.setEventEmailSubject(eventId, eventEmailSubject));
    };
  
    const setEventEmailBody = (eventEmailBody: string) => {
      dispatch(EventActions.setEventEmailBody(eventId, eventEmailBody));
    };

  const openEditModal = () => {
    dispatch(AppActions.pushModal(ModalTypes.EmailHoldersModal));
  };
  const closePreviewModal = () => {
    popModal();
    popModal();
    setEventEmailBody("")
    setEventEmailSubject("")
  };

  const [saveEmailTemplate, { loading:templateLoading }] = useMutation(EVENT_EMAIL_TEMPLATE, {
    onCompleted() {
      dispatch(AppActions.showNotification("Your Email has been sent.", AppNotificationTypeEnum.Success));
      closePreviewModal();
    },
    onError(error) {
     dispatch(AppActions.showNotification(getErrorMessage(error), AppNotificationTypeEnum.Error));
     
    }
  });

  const eventTimeZone= event?.venue?.address?.timezone
  const performance =
  event?.performances?.[0].schedule && event?.performances?.[0].schedule;
  const emailTimeFormat =
  performance && performance.length > 0
    ? performance
        .map(
          (dates) =>
            `${Time.format(
              dates.startsAt,
              "ddd, MMM Do",
              eventTimeZone
            )} | doors ${Time.format(dates.doorsAt, "h:mma", eventTimeZone)} | show ${Time.format(dates.startsAt, "h:mma", eventTimeZone)}`
        )
        .join(", ") 
    : "";
  const emailTempleteBodyhtml = `<!DOCTYPE html> 
  <html>
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Ticket Confirmation</title>
      <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f5f5f5;
          }
          p {
              margin: 0;
          }
          .container {
            background: #ffffff;
            padding: 10px;
            border: 1px solid #cacaca;
            border-radius: 10px;
          }
          .header-wrapper{
            display: flex;
            gap: 12px;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #e1e1e1;
          }
          .header-wrapper img {
            width: 100%;
            max-width: 120px;
            border-radius: 5px;
            object-fit: cover;
          }

          .header-content .date-time{
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            text-transform: uppercase;
            color: #e63946;
            font-family: Work Sans, Helvetica;
            display: block;
          }

          .header-content h2{
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: #333333;
            margin: 5px 0;
            font-family: Work Sans, Helvetica;
          }

          .header-content p{
            font-size: 12px;
            font-weight: 400;
            line-height: 1.4;
            color: #333333;
            font-family: Work Sans, Helvetica;
          }

          .event-info{
            padding-top: 15px;
            // border-top: 1px solid #e1e1e1;
          }

          .event-info{
           display: flex;
            gap: 12px;
            align-items: center;
          }
          .event-info img{
            width: 100%;
            max-width: 150px;
            border-radius: 5px;
            object-fit: cover;
          }

          .venue-wrapper .venue{
            font-family: Work Sans, Helvetica;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: #333333;
          }

          .venue-wrapper .address{
            font-family: Work Sans, Helvetica;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #333333;
          }

          .venue-wrapper .direction{
            color: #ff6802;
            text-decoration: none;
            font-size: 12px;
          }

          .event-wrapper{
            margin-top:10px;
          }

          .event-wrapper .event-title{
            background-color: #333333;
            padding: 10px
          }
          
           .event-wrapper .event-title span{
            color: #e1e1e1;
          }

          .event-wrapper .event-doors{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
          }

          .event-wrapper .event-doors span{
            color: #333333;
            font-size: 12px;
          }

          .event-wrapper .time-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
          }

          .event-wrapper .event-venue{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 10px;
          }

          .event-wrapper .event-venue span{
            color: #333333;
            font-size: 12px;
          }

          .event-wrapper .event-Fashion {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:4px 10px;
          }
          .event-wrapper .event-Fashion span{
            color: #333333;
            font-size: 12px;
          }

          .event-wrapper .event-border{
            background-color: #ff6802;
            height: 4px;
            width: 90%;
            margin: auto;
            margin-top: 8px;
          }

          .event-wrapper .add-calendar{
            padding:10px
          }

          .event-wrapper .add-calendar p{
            color: #333333;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.2;
            padding-bottom: 5px;
          }

          .event-wrapper .add-calendar span{
           color:#ff6802;
          }

           .event-wrapper .customer-support{
            padding: 0 10px
          }

          .event-wrapper .customer-support p{
            color: #333333;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.2;
            padding-bottom: 5px;
          }

          .event-wrapper .customer-support span{
           color:#ff6802;
          }
           .sellout_logo{
            display: block;
            margin: 0 auto 20px;
            width: 90px;
           }
            .border_bottam{
            border-bottom: 1px solid #e1e1e1;
            padding: 8px;
            }
          
      </style>
  </head>
  <body>
         <div >
            <img class="sellout_logo" src=${SelloutDarkLogo}>
        </div>
        <div class="container">

          <div class="header-wrapper">
              <img src=${event?.posterImageUrl}>
              <div class="header-content">
                <span class="date-time">${emailTimeFormat} </span>
                <h2>${event?.name}</h2>
                <p>${event?.venue?.name} | ${event?.venue?.address.city}, ${event?.venue?.address.state}</p>
              </div>
          </div>
           <div class="border_bottam">${eventEmailBody}</div>
          <div class="event-info">
              <img src=${event?.venue?.imageUrls}>
              <div class="venue-wrapper">
              <p class="venue"><strong>${event?.venue?.name}</strong></p>
              <p class="address"> ${event?.venue?.address.city}, ${event?.venue?.address.state}</p>
              <a class="direction" href="#">Get Directions</a>
              </div>
          </div>

          <div class="event-wrapper">
          ${performance && performance.length > 0 
            ? performance.map((dates, index) => `
                <div class="event-title">
                  <span>Day ${index + 1}</span>
                </div>
          
                <div class="event-doors">
                  <span class="date-time">DOORS AT: ${Time.format(dates.doorsAt, "ddd, MMM Do", eventTimeZone)}</span>
                  <span class="date-time">SHOW AT: ${Time.format(dates.startsAt, "ddd, MMM Do", eventTimeZone)}</span>
                </div>
          
                <div class="time-wrapper">
                  <p>${Time.format(dates.doorsAt, "h:mma", eventTimeZone)}</p>
                  <p>${Time.format(dates.startsAt, "h:mma", eventTimeZone)}</p>
                </div>
          
                <div class="event-venue">
                  <span class="date-time">${event?.venue?.name}</span>
                  <span class="date-time">${event?.venue?.name}</span>
                </div>
          
                <div class="event-Fashion">
                  <span class="date-time">${event?.venue?.address?.city}, ${event?.venue?.address?.state}</span>
                  <span class="date-time">${event?.venue?.address?.city}, ${event?.venue?.address?.state}</span>
                </div>
          
                <div class="event-border"></div>
          
                <div class="add-calendar">
                  <p>Add to Calendar</p>
                  <span>Google Calendar | Microsoft Outlook | Yahoo! Calendar</span>
                </div>
            `).join("") 
            : ""}
            <div class="customer-support">
              <p>Customer Support</p>
              <span>Visit Help Center   |   Contact Sellout</span>
            </div>
            </div>
          
        </div>
  </body>
  </html>`;
  return (
    <ModalContainer width="450px">
      <ModalHeader title={title} close={closePreviewModal} />
      <ModalContent>
        <Text
          dangerouslySetInnerHTML={{
            __html: emailTempleteBodyhtml as string,
          }}
        />
      </ModalContent>
      <ModalFooter>
        <div />
        <ButtonContainer>
          <Button
            type={ButtonTypes.Thin}
            text={editText}
            state={ButtonStates.Warning}
            margin="0px 10px 0px 0px"
            onClick={openEditModal}
          />
          <Button
            type={ButtonTypes.Thin}
            text={confirmText}
            state={ButtonStates.Active}
            margin="0px 10px 0px 0px"
            loading={templateLoading}
            onClick={() => {
              saveEmailTemplate({
                variables: {
                  query: {
                    eventId: eventId,
                    body: previewEmailBody,
                    subject: previewEmailSubject,
                  },
                },
              });
            }}
          />
        </ButtonContainer>
      </ModalFooter>
    </ModalContainer>
  );
};

export default EmailHoldersPreviewModal;
