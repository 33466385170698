import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { BackstageState } from "../redux/store";
import {
  Checkbox,
  FormGroup,
  SectionHeader,
  TabBody,
  TabContainer,
} from "./modal/RefundModal";
import { Colors, Flex, Icon, Icons } from "@sellout/ui";
import styled from "styled-components";
import * as OrderActions from "../redux/actions/order.actions";
import OrderUtil from "@sellout/models/.dist/utils/OrderUtil";
import * as Price from "@sellout/utils/.dist/price";
import ScreenHeaderMyTicket from "./ScreenHeaderMyTicket";

type CheckBox = {
  checked?: boolean;
  disabled?: boolean;
};
type SelectTicketProps = {
  data: any;
  stepState?: any;
  setStepState?: any;
  type?: string;
  noTicket?: any;
};
const Labels = styled.label<CheckBox>`
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ff700f;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
  }
  &:after {
    content: "";
    display: ${(props) => (props.checked ? "block" : "none")};
    position: absolute;
    top: 2px;
    left: 5.5px;
    width: 2px;
    height: 6px;
    border: solid #ff700f;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;
export const Border = styled.div`
  width: 100%;
  height: 2px;
  background: #f2f2f2;
  margin: 7px 0px 7px 0px;
`;
export const IconContainer = styled.div`
  text-align: center;
  &.hide {
    display: none;
  }
`;
const Text = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${Colors.Grey1};
  margin-top: 16px;
`;
export enum OrderItemStateEnum {
  Active = "Active",
  Canceled = "Canceled",
  Offered = "Offered",
  Shared = "Shared"
}

export const SelectTicketAddons: React.FC<SelectTicketProps> = ({
  data,
  stepState,
  setStepState,
  type,
  noTicket,
}) => {
  const dispatch = useDispatch();
  const orderState = useSelector((state: BackstageState) => state.order);
  const { selectedTickets, selectedAddons } = orderState;
  const message = `There are no tickets/upgrades to be ${
    type ? "scanned" : "shared"
  }`;
  const handleSelectAllTickets = (ticketsData) => {
    const allTicketIds =
    ticketsData?.filter(
          (ticket) =>
            ticket?.state === OrderItemStateEnum.Active &&(type !== ""
              ? ticket?.scan.every((scan) => scan.scanned === false)
              : true)
        )
        .map((ticket) => ticket._id) || [];
    if (selectedTickets.length === allTicketIds.length) {
      dispatch(OrderActions.setTicket([] as any));
    } else {
      dispatch(OrderActions.setTicket(allTicketIds));
    }
  };

  const handleSelectAllUpgrades = (upgradesData) => {
    const allUpgradeIds =
    upgradesData?.filter(
          (upgrade) =>
            upgrade.state === OrderItemStateEnum.Active &&
            (type !== "" ? upgrade?.scan.scanned === false : true)
        )
        .map((upgrade) => upgrade._id) || [];
    if (selectedAddons.length === allUpgradeIds.length) {
      dispatch(OrderActions.setAddons([] as any));
    } else {
      dispatch(OrderActions.setAddons(allUpgradeIds));
    }
  };

  const handleSelectTicket = (ticketId) => {
    const updatedSelectedTickets: any = selectedTickets.includes(ticketId)
      ? selectedTickets.filter((id) => id !== ticketId)
      : [...selectedTickets, ticketId];
    dispatch(OrderActions.setTicket(updatedSelectedTickets));
  };

  const handleSelectUpgrade = (upgradeId) => {
    const updatedSelectedUpgrades: any = selectedAddons.includes(upgradeId)
      ? selectedAddons.filter((id) => id !== upgradeId)
      : [...selectedAddons, upgradeId];
    dispatch(OrderActions.setAddons(updatedSelectedUpgrades));
  };
  let discountCode = data?.order?.discountCode;

  let promotionsCode = data?.order?.event?.promotions.filter(
    (promo) => promo?.code?.toLowerCase() === discountCode?.toLowerCase()
  );

  let promotionCodePerOrder =
    promotionsCode &&
    promotionsCode.length !== 0 &&
    promotionsCode[0]?.appliesTo === "Per Order";
  let subtotal = OrderUtil.orderSubtotal(data?.order);

  const percentage = (price: any) => {
    const amount = price;
    const totalAmount = parseFloat(amount);
    return Price.output(totalAmount.toFixed(2), true);
  };
  const pricePerTicketOrder = (ticket) => {
    if (promotionCodePerOrder) {
      let perTicketDiscount =
        (data.order.discountAmount / subtotal) * ticket.price;
      let ticketWithDiscount = ticket.price - perTicketDiscount;
      return ticketWithDiscount;
    }
  };
  const pricePerUpgradeOrder = (upgrade) => {
    if (promotionCodePerOrder) {
      let perUpgradeDiscount =
        (data.order.discountAmount / subtotal) * upgrade.price;
      let upgradeWithDiscount = upgrade.price - perUpgradeDiscount;
      return upgradeWithDiscount;
    }
  };


    const filteredforScanTickets = data?.order?.tickets.filter(
      (activeTickets) => activeTickets?.state == OrderItemStateEnum.Active &&activeTickets.isTicketSharing 
    );
  const filteredTickets = data?.order?.tickets.filter((activeTicket) => {
    const matchingTicketType = data?.order?.event?.ticketTypes?.find(
      (ticketType) => ticketType._id === activeTicket.ticketTypeId
    );
  
    return (
      activeTicket?.state === "Active" &&
      matchingTicketType?.isTicketSharing 
    );
  });
  
  const scannedTickets = filteredforScanTickets?.filter((ticket) => {
    return ticket?.scan.every((scan) => scan.scanned === false);
  });

  // Upgrades details section
  const filteredForUpgrades = data?.order?.upgrades.filter(
    (activeUpgrades) => activeUpgrades?.state == OrderItemStateEnum.Active&&activeUpgrades?.isAddOnsSharing
  );
  const filteredUpgrades = data?.order?.upgrades?.filter((activeUpgrade) => {
    const matchingUpgradeType = data?.order?.event?.upgrades?.find(
      (upgradeType) => upgradeType._id === activeUpgrade.upgradeId
    );
  
    return (
      activeUpgrade?.state === "Active" &&
      matchingUpgradeType?.isAddOnsSharing
    );
  });
  
  const scannedUpgrades = filteredForUpgrades?.filter((upgrades) => {
    return upgrades?.scan?.scanned === false;
  });
  const ticketsToDisplay = type && type !== ""  ? scannedTickets : filteredTickets;
  const upgradesToDisplay = type && type !== ""? scannedUpgrades : filteredUpgrades;

  // When their is not tickets/upgrades
  const noTicketUpgrades =
    filteredTickets?.length == 0 && filteredUpgrades?.length == 0;

    useEffect(() => {
      if (type !== "" && typeof noTicket === "function") {
        noTicket(noTicketUpgrades);
      }
    }, [noTicketUpgrades]);
    const allTicketsSelected =
    selectedTickets.length ===
    (ticketsToDisplay?.filter((ticket) => {
      const isScanFalse =
        type !== ""
          ? ticket.scan.every((scan) => scan.scanned === false)
          : true;
      return ticket.state === OrderItemStateEnum.Active && isScanFalse;
    }).length || 0);
  const selectableUpgrades =
  upgradesToDisplay?.filter(
    (upgrade) =>
      upgrade.state === OrderItemStateEnum.Active &&
      (type !== "" ? upgrade?.scan.scanned === false : true)
  ) || [];

const allUpgradesSelected =
  selectedAddons.length === selectableUpgrades.length;
  return (
    <>
      <TabContainer>
        {noTicketUpgrades ? (
          <IconContainer>
            <Icon
              icon={Icons.Warning}
              color={Colors.Orange}
              size={type ? 30 : 48}
            />
            <Text>{message}</Text>
          </IconContainer>
        ) : (
          !type && (
            <ScreenHeaderMyTicket
              title="Select Tickets/Add-ons"
              padding="1px 2px 24px"
              stepState={stepState}
              setStepState={setStepState}
            />
          )
        )}
        <TabBody>
          {ticketsToDisplay?.length > 0 && (
            <>
              <FormGroup onClick={() => handleSelectAllTickets(ticketsToDisplay)}>
                <Checkbox type="checkbox" checked={allTicketsSelected} />
                <Labels checked={allTicketsSelected} className="alignItems">
                  <SectionHeader>
                    Tickets
                    <Icon
                      icon={Icons.TicketRegular}
                      color={Colors.Grey1}
                      size={12}
                      margin="0px 10px 0px 0px"
                      position="absolute"
                      top="2px"
                      left="72px"
                    />
                  </SectionHeader>
                </Labels>
              </FormGroup>
              {ticketsToDisplay?.map((ticket, index) => {
                let totalPriceWithDiscount = pricePerTicketOrder(ticket);
                const finalValue = totalPriceWithDiscount
                  ? totalPriceWithDiscount
                  : ticket.price;
                const isChecked =
                  ticket.state === OrderItemStateEnum.Active
                    ? selectedTickets.includes(ticket._id)
                    : true;

                return (
                  <Flex key={index}>
                    <FormGroup onClick={() => handleSelectTicket(ticket._id)}>
                      <Checkbox type="checkbox" />
                      <Labels className="alignItems" checked={isChecked}>
                        {ticket.name}
                        {ticket.seat ? ` (${ticket.seat}) ` : null} ($
                        {`${percentage(finalValue)}`})
                      </Labels>
                    </FormGroup>
                  </Flex>
                );
              })}
            </>
          )}
          {noTicketUpgrades ? "" : <Border />}

          {upgradesToDisplay?.length > 0 && (
            <>
              <FormGroup onClick={()=>handleSelectAllUpgrades(upgradesToDisplay)}>
                <Checkbox type="checkbox" checked={allUpgradesSelected} />
                <Labels checked={allUpgradesSelected} className="alignItems">
                  <SectionHeader>
                    Add-ons
                    <Icon
                      icon={Icons.UpgradeRegular}
                      color={Colors.Grey1}
                      size={12}
                      margin="0px 10px 0px 0px"
                      position="absolute"
                      top="0px"
                      left="79px"
                    />
                  </SectionHeader>
                </Labels>
              </FormGroup>
              {upgradesToDisplay?.map((upgrade, index) => {
                if (upgrade.refund.refunded) return null;
                let totalUpgradeWithDiscount = pricePerUpgradeOrder(upgrade);
                const finalValue = totalUpgradeWithDiscount
                  ? totalUpgradeWithDiscount
                  : upgrade.price;
                const isChecked = selectedAddons.includes(upgrade._id);
                return (
                  <Flex key={index}>
                    <FormGroup onClick={() => handleSelectUpgrade(upgrade._id)}>
                      <Checkbox type="checkbox" />
                      <Labels className="alignItems" checked={isChecked}>
                        {upgrade.name} (${`${percentage(finalValue)}`})
                      </Labels>
                    </FormGroup>
                  </Flex>
                );
              })}
            </>
          )}
        </TabBody>
      </TabContainer>
    </>
  );
};
