import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Button, {
  ButtonTypes,
  ButtonStates,
} from "@sellout/ui/build/components/Button";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalTypes,
} from "./Modal";
import { Flex, Input } from "@sellout/ui";
import { InputSizes } from "@sellout/ui/build/components/Input";
import RichTextEditor from "../../elements/RichTextEditor";
import * as EventActions from "../../redux/actions/event.actions";
import { BackstageState } from "../../redux/store";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import { AppNotificationTypeEnum } from "../../models/interfaces/IAppNotification";
import IEmailHolders from "@sellout/models/.dist/interfaces/IEmailHolders";

enum emailFieldsLabelText {
  subject = "Email subject",
  body ="Email body"
}
const ButtonContainer = styled.div`
  display: flex;
`;

const EmailHoldersModal = () => {
  /* Actions */
  const eventState = useSelector((state: BackstageState) => state.event);
  const { eventId, eventsCache } = eventState;

  const event = eventsCache[eventId];

  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  let title = "Compose Message";
  let cancelText = "CANCEL";
  let previewText = "PREVIEW";

  const eventEmailSubject = event?.eventEmailSubject; 
  const eventEmailBody = event?.eventEmailBody; 

  const setEventEmailSubject = (eventEmailSubject: string) => {
    dispatch(EventActions.setEventEmailSubject(eventId, eventEmailSubject));
  };

  const setEventEmailBody = (eventEmailBody: string) => {
    dispatch(EventActions.setEventEmailBody(eventId, eventEmailBody));
  };

  const closeComposeModal = () => {
    popModal()
    popModal()
    setEventEmailBody("")
    setEventEmailSubject("")

  };
  const openPreview = async () => {
  // validation
     const validate = EventUtil.ValidateEventEmailSubjectFields({eventEmailSubject,eventEmailBody} as IEmailHolders)
     const validationErrors =
       validate?.error?.details?.map((detail: any) => detail.message) ?? [];
   
     if (validationErrors.length > 0) {
       dispatch(
         AppActions.showNotification(
           validationErrors.join("\n"),
           AppNotificationTypeEnum.Error
         )
       );
       return;
     }
     popModal();
     dispatch(AppActions.pushModal(ModalTypes.EmailHoldersPreviewModal));
   };
  return (
    <ModalContainer width="450px">
      <ModalHeader title={title} close={closeComposeModal} />
      <ModalContent>
        <Flex direction="column" gap="6px">
          <Input
            type="text"
            value={event?.eventEmailSubject as string}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEventEmailSubject(e.currentTarget.value)
            }
            autoFocus={true}
            size={InputSizes.Large}
            label={emailFieldsLabelText.subject}
          />
          <RichTextEditor
            value={event?.eventEmailBody as string}
            onChange={(value:string) =>
              setEventEmailBody(value)
            }
            label={emailFieldsLabelText.body}
          />
        </Flex>
      </ModalContent>
      <ModalFooter>
        <div />
        <ButtonContainer>
          <Button
            type={ButtonTypes.Thin}
            text={cancelText}
            state={ButtonStates.Warning}
            margin="0px 10px 0px 0px"
            onClick={() => popModal()}
          />
          <Button
            type={ButtonTypes.Thin}
            text={previewText}
            state={ButtonStates.Active}
            margin="0px 10px 0px 0px"
            onClick={openPreview}
          />
        </ButtonContainer>
      </ModalFooter>
    </ModalContainer>
  );
};

export default EmailHoldersModal;
