import React from "react";
import styled from "styled-components";
import { Button, Flex } from "@sellout/ui";
import { ButtonTypes, ButtonStates } from "@sellout/ui/build/components/Button";
import { useSelector, useDispatch } from "react-redux";
import { ButtonContainer } from "../../pages/EventOrders.page";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import { SelectTicketAddons } from "../SelectTicketAddons";
import { BackstageState } from "../../redux/store";
import {useMutation, useQuery } from "@apollo/client";
import GET_ORDER from "@sellout/models/.dist/graphql/queries/order.query";
import SCAN_ORDER from "@sellout/models/.dist/graphql/mutations/scanOrder.mutation";
import * as AppActions from "../../redux/actions/app.actions";
import * as OrderActions from "../../redux/actions/order.actions";
import { getErrorMessage } from "@sellout/ui/build/utils/ErrorUtil";
import { AppNotificationTypeEnum } from "../../models/interfaces/IAppNotification";


const Container = styled.div`
  width: 375px;
`;

const CheckInTicketModal = () => {
  const orderState = useSelector((state: BackstageState) => state.order);
  const { orderId } = orderState;
  const { selectedTickets, selectedAddons } = orderState;
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = React.useState(false as boolean);
  const [noTicketScanned, setNoTicketScanned] = React.useState<boolean>();

  const { data } = useQuery(GET_ORDER, {
    variables: {
      orderId,
    },
  });

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  const [scanOrder, { loading: checkInLoading }] = useMutation(SCAN_ORDER, {
    refetchQueries: [{ query: GET_ORDER, variables: { orderId } }],
    onError(error) {
      dispatch(AppActions.showNotification(getErrorMessage(error), AppNotificationTypeEnum.Error));
    },
    onCompleted() {
      dispatch(OrderActions.setTicket(""));
      dispatch(OrderActions.setAddons(""));
      dispatch(
        AppActions.showNotification(
          "Ticket scanned successfully.",
          AppNotificationTypeEnum.Success
        )
      );
      popModal();
    },
  });
  const buttonState = selectedTickets.length > 0 || selectedAddons.length > 0;

  return (
    <>
      <ModalContainer display={showConfirm ? "none" : "block"}>
        <ModalHeader title={"Check In Tickets"} close={popModal} />
        <ModalContent padding="15px">
          <Container>
            <SelectTicketAddons
              data={data}
              type="CheckInSecreen"
              noTicket={setNoTicketScanned}
            />
          </Container>
        </ModalContent>

        <ModalFooter>
          <div />
          {!noTicketScanned && (
            <ButtonContainer>
              <Button
                type={ButtonTypes.Thin}
                text={"Submit"}
                state={
                  buttonState ? ButtonStates.Active : ButtonStates.Disabled
                }
                margin="0px 10px 0px 0px"
                loading={checkInLoading}
                onClick={() => setShowConfirm(true)}
              />
            </ButtonContainer>
          )}
        </ModalFooter>
      </ModalContainer>
      {showConfirm && (
        <ConfirmActionModal
          title="Scanned Confirmation"
          message="Are you sure you want to mark this ticket as scanned?"
          cancel={() => {
            setShowConfirm(false);
          }}
          loading={checkInLoading || false}
          confirm={() => {
            scanOrder({
              variables: {
                orderId,
                ticketIds: selectedTickets,
                upgradeIds: selectedAddons,
              },
            });
          }}
        />
      )}
    </>
  );
};
const ConfirmActionModal = ({
  title = "Are you sure you want to mark this ticket as unscanned?",
  message,
  confirm,
  confirmText = "CONFIRM",
  cancel,
  cancelText = "CANCEL",
  loading = false,
}) => {
  return (
    <ModalContainer display="block" position="absolute" left="40%" top="40%">
      <ModalHeader title={title} close={cancel} />
      <Container>
        <ModalContent padding="20px">{message}</ModalContent>
      </Container>
      <ModalFooter>
        <div />
        <Flex>
          {cancel && (
            <Button
              type={ButtonTypes.Thin}
              state={ButtonStates.Warning}
              text={cancelText}
              margin="0 10px 0 0"
              onClick={() => {
                if (cancel) cancel();
              }}
            />
          )}
          <Button
            type={ButtonTypes.Thin}
            text={confirmText}
            onClick={() => {
              if (confirm) confirm();
            }}
            loading={loading}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default CheckInTicketModal;
