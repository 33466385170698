import React from "react";
import { Logo, LogoContainer } from "../account/AccountStyle";
import { useMutation, useQuery} from "@apollo/client";
import { BackstageState } from "../../redux/store";
import * as OrderActions from "../../redux/actions/order.actions";
import SelloutLogo from "../../assets/images/sellout-logo-long-white.svg";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import {
  HeaderBar,
  HeaderBarText,
  Row,
} from "../ScreenHeaderMyTicket";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button, Colors } from "@sellout/ui";
import { ModalContainer, ModalFooter } from "./Modal";
import {
  MainWrapper,
  ScreenFooter,
  ScreenStep,
  SharingDetails,
} from "../MyTicketShareScreenSteps";
import { ButtonStates, ButtonTypes } from "@sellout/ui/build/components/Button";
import { SectionHeader } from "./RefundModal";
import { EventDetailsRight, Section } from "./UserOrderTicketModal";
import { CheckBox, Checkbox, RadioButtons } from "../account/AccountModal";
import SHARING_TICKET_STATUS_CHANGE from "@sellout/models/.dist/graphql/mutations/sharingTicketStatusChange.mutation";
import { SharedOrderItemStatusEnum } from "@sellout/models/.dist/interfaces/IOrderState";



const Text = styled.div`
  font-size: 1.6rem;
  color: ${Colors.Grey2};
  padding: 10px 20px;
  text-align: left;
  line-height: 24px;
`;
export const UserName = styled.span`
  font-weight: 800;
  font-size: 1.5rem;
  color: ${Colors.Grey1};
`;
const FormGroup = styled.div`
  display: flex;
  margin: 20px 14px 13px 18px;
`;
export const Labels = styled.label<CheckBox>`
  position: relative;
  padding: 0 0 0 26px;
  cursor: pointer;
  &:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid ${(props) => (props.checked ? "#ff700f" : "#ddd")};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50%; 
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 1px;
  }
  &:after {
    content: "";
    display: ${(props) => (props.checked ? "block" : "none")};
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin: 1px;
    background: #ff700f;
    position: absolute;
    top: 5px;
    left: 4px;
  }
`;
const Waiver = styled.div`
  font-family: inherit !important; /* Ensure it inherits the font */
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 400;
  color: ${Colors.Grey2};

  h1, h2, h3, h4, h5, h6, p, u, br {
    font-family: inherit !important;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
`;
enum DisplayStates {
  AcceptWavier = "Accept waiver",
  SharingOverview = "Sharing overview",
  AcceptTickets = "Accept tickets",
  Confirmation = "Confirmation",
}
enum ConfirmText {
  confirmText = "CONFIRM",
  next = "NEXT",
  wavier="AGREE TO WAIVER" 
} 
const Invite = () => {
  const orderState = useSelector((state: BackstageState) => state.order);
  const { acceptModalDetails } = orderState;
  return (
    <>
      <HeaderBar padding="18px 19px 14px">
        <Row>
          <HeaderBarText fontSize="1.7rem">{"Sharing overview"}</HeaderBarText>
        </Row>
      </HeaderBar>
      <Text>
        Your friend, <UserName>{acceptModalDetails?.firstName} {acceptModalDetails?.lastName}</UserName>, has shared these tickets
        with you. If you accept them, then you will receive a QR code to use for
        admission to the event
      </Text>
      {acceptModalDetails?.tickets?.length ? (
      <Section>
        <SectionHeader>
          <EventDetailsRight>Tickets</EventDetailsRight>
        </SectionHeader>
        <MainWrapper>
          <>
            {acceptModalDetails?.tickets?.map((item) => {
           return (
             <SharingDetails>{item?.name}{item?.seat ? ` (${item?.seat}) ` : null}</SharingDetails>
           )
             })}
          </>
        </MainWrapper>
      </Section>
 ) : (
  ""
)}
   {acceptModalDetails?.upgrades?.length ? (
      <Section>
        <SectionHeader>
          <EventDetailsRight>Upgrade</EventDetailsRight>
        </SectionHeader>
        <MainWrapper>
          <>
          {acceptModalDetails?.upgrades?.map((item) => {
           return (
            <SharingDetails>{item?.name}</SharingDetails>
          )
        })}
          </>
        </MainWrapper>
      </Section>
      ) : (
        ""
      )}
    </>
  );
};
const LiabilityWaiver = () => {
  const orderState = useSelector((state: BackstageState) => state.order);
  const { acceptModalDetails } = orderState;
  /** Render **/
  return (
    <><HeaderBar padding="18px 19px 14px">
      <Row>
        <HeaderBarText fontSize="1.7rem">{"Waiver"}</HeaderBarText>
      </Row>
    </HeaderBar>
    <Text>
    <Waiver
          dangerouslySetInnerHTML={{
            __html:acceptModalDetails?.userAgreement}}
        />
      </Text></>
  );
};
const AcceptTickets = () => {
  const dispatch = useDispatch();
  const orderState = useSelector((state: BackstageState) => state.order);
  const { confirmTicket,acceptModalDetails } = orderState;
  

  const setUserConfirmTicket = (value) => {
    dispatch(OrderActions.SetUserConfirmTicket(value));
  }


  return (
    <>
      <HeaderBar padding="18px 19px 14px">
        <Row>
          <HeaderBarText fontSize="1.7rem">{"Accept tickets"}</HeaderBarText>
        </Row>
      </HeaderBar>

      <Text>Do you want to accept these tickets?</Text>

      <RadioButtons>
        <FormGroup onClick={() => setUserConfirmTicket(true)}>
          <Checkbox type="checkbox" />
          <Labels checked={confirmTicket}>Yes, I accept these tickets.</Labels>
        </FormGroup>
        <FormGroup onClick={() => setUserConfirmTicket(false)}>
          <Checkbox type="checkbox" />
          <Labels checked={!confirmTicket}>
            No, please return these tickets to <UserName>{acceptModalDetails?.firstName} {acceptModalDetails?.lastName}</UserName>.
          </Labels>
        </FormGroup>
      </RadioButtons>
    </>
  );
};

const AcceptShareTicketModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orderState = useSelector((state: BackstageState) => state.order);
  const { acceptModalDetails,confirmTicket } = orderState;
  const isUserAgreement= acceptModalDetails?.userAgreement !==""
  const [displayState, setDisplayState] = React.useState(
    DisplayStates.SharingOverview
  );

  const sharingId =acceptModalDetails?.sharing?._id
  const orderId =acceptModalDetails?._id
  const status = confirmTicket?SharedOrderItemStatusEnum.Accepted:SharedOrderItemStatusEnum.Rejected
  const [saveSharingDetails, { loading: savingLoading }] = useMutation(SHARING_TICKET_STATUS_CHANGE, {
    fetchPolicy: "network-only",
    onCompleted() {
      dispatch(AppActions.popModal());
      dispatch(OrderActions.SetSharedLoginEmail(""));
      dispatch(OrderActions.SetSharedLoginPhoneNumber(""));
      history.push("/admin/dashboard")
      history.push("/my-tickets");
      dispatch(OrderActions.SetConfirmAcceptModal(false))
      dispatch(OrderActions.SetUserConfirmTicket(true))
    },
    });
  
  const Footer = () => {
    const handleNext = () => {
      switch (displayState) {
        case DisplayStates.SharingOverview:
          return true;
        case DisplayStates.AcceptTickets:
          return true;
        default:
          return false;
      }
    };

    // The handling the click on footer button on steps
    
    const handleClickNext = () => {
      switch (displayState) {
        case DisplayStates.SharingOverview:
          if (isUserAgreement) {
            setDisplayState(DisplayStates.AcceptWavier);
          } else {
            setDisplayState(DisplayStates.AcceptTickets);
          }
          break;
        case DisplayStates.AcceptWavier:
          setDisplayState(DisplayStates.AcceptTickets);
          break;
        case DisplayStates.AcceptTickets:
          saveSharingDetails({
            variables: {
              sharingId,
              orderId,
              status
            },
          });
          break;
        default:
          break;
      }
    };
    
    return (
      <ModalFooter>
        <ScreenFooter>
          <div />
          <Button
            type={ButtonTypes.Next}
            text={
              displayState === DisplayStates.AcceptWavier
                ? ConfirmText.wavier
                : displayState === DisplayStates.SharingOverview
                ? ConfirmText.next
                : ConfirmText.confirmText
            }
            state={ButtonStates.Active}
            onClick={handleClickNext}
            loading={savingLoading}
          />
        </ScreenFooter>
      </ModalFooter>
    );
  };

  const renderStep = () => {
    // All shared screen details are in steps to render
    switch (displayState) {
      case DisplayStates.SharingOverview:
        return (
          <Invite
          />
        );
        case DisplayStates.AcceptWavier:
          return (
            <LiabilityWaiver
            />
          );
        case DisplayStates.AcceptTickets:
          return (
            <AcceptTickets
            />
          );
      default:
        return null;
    }
  };
  return (
    <ModalContainer>
      <LogoContainer>
        <Logo src={SelloutLogo} />
      </LogoContainer>
      <ScreenStep className="menu-head-wrapper">
      {renderStep()}
      </ScreenStep>
      <Footer />
    </ModalContainer>
  );
};

export default AcceptShareTicketModal;
